import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { EbxProvider } from '@ebx-ui/ebx-ui-component-library-sdk';
import { Amplify } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

import './index.css';

import * as API from 'api/api';
import { AWS_AMPLIFY_CONFIG } from 'constants/amplify';
import { AuthProvider } from 'context/authContext';
import * as environment from 'utils/environment';
import { sentry } from 'constants/sentry';
import { mixpanelConfig } from 'constants/mixpanel';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { browserTracingIntegration } from '@sentry/react';

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return undefined;
  }

  const { worker } = await import('./mocks/browser');

  return worker.start({ onUnhandledRequest: 'bypass' });
}

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

// Configure Amplify
Amplify.configure(AWS_AMPLIFY_CONFIG);

// Set the version in the window sesion
if (!sessionStorage.getItem('currentVersion')) {
  API.getVersion()
    .then(version => {
      sessionStorage.setItem('currentVersion', version);
    })
    .catch(() => {});
}

const hostType = environment.getHostType();
const sentryConfig = sentry[hostType];

if (sentryConfig) {
  Sentry.init({
    dsn: sentryConfig.dsn,
    environment: hostType,
    integrations: [browserTracingIntegration()],
  });
}

const mixPanelToken = mixpanelConfig[hostType]?.token;
if (mixPanelToken) {
  mixpanel.init(mixPanelToken);
}

enableMocking().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <EbxProvider resetCSS={true}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </EbxProvider>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
